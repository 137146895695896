<template>
  <div class="equipment">
    <div class="equipment-bar" v-if="$route.path.indexOf('general') < 0">
      <div class="bar-left">
        <el-form ref="form" label-width="80px">
          <el-form-item label="设备编号">
            <el-select
              v-model="equipment"
              placeholder="请选择"
              :popper-append-to-body="false"
              @change="handleChangeNum"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.id"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-form
          ref="form"
          label-width="80px"
          v-if="barActive == 4"
          style="margin-top: 10px"
        >
          <el-form-item label="维保属性">
            <el-select
              v-model="recordType"
              placeholder="请选择维保属性"
              @change="handleChangeRecordType"
              :popper-append-to-body="false"
            >
              <el-option label="半月保" value="1"></el-option>
              <el-option label="季度保" value="2"></el-option>
              <el-option label="半年保" value="3"></el-option>
              <el-option label="年度保" value="4"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="bar-main">
        <ul>
          <li
            v-for="(item, ind) in tabData"
            :key="item.value"
            @click="handleClickBar(item.path, ind)"
            :class="barActive === ind ? 'active' : ''"
          >
            {{ item.label }}
          </li>
        </ul>
      </div>
      <div class="bar-right" v-if="barActive == 4">
        <!-- <el-form ref="form" label-width="80px">
          <el-form-item label="维保属性">
            <el-select
              v-model="recordType"
              placeholder="请选择维保属性"
              @change="handleChangeRecordType"
              :popper-append-to-body="false"
            >
              <el-option label="半月保" value="1"></el-option>
              <el-option label="季度保" value="2"></el-option>
              <el-option label="半年保" value="3"></el-option>
              <el-option label="年度保" value="4"></el-option>
            </el-select>
          </el-form-item>
        </el-form> -->
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { getDeviceData } from '@/api/list'
export default {
  data() {
    return {
      equipment: '',
      options: [],
      tabData: [
        {
          label: '实时监测',
          value: '0',
          path: '/equipment/monitor'
        },
        {
          label: '安全性能评估',
          value: '1',
          path: '/equipment/safetyPerformance'
        },
        {
          label: '寿命预测',
          value: '2',
          path: '/equipment/lifeForecast'
        },
        {
          label: '维修路径规划',
          value: '3',
          path: '/equipment/maintain'
        },
        {
          label: '维保策略',
          value: '4',
          path: '/equipment/strategy'
        }
      ],
      recordType: '1',
      barActive: 0
    }
  },
  computed: {
    equipmentChange() {
      this.getEquipment()
      return this.$store.state.equipment
    },
    uid() {
      return this.$store.state.uid
    }
  },
  watch: {
    $route: {
      handler(val) {
        this.equipment = val.query.equipment
        this.recordType = val.query.recordType
      }
      // immediate: true
    }
  },
  created() {
    getDeviceData({ id: this.uid }).then((res) => {
      this.options = res
      this.getEquipment()
    })
    // 设置标签样式
    const barActive =
      this.tabData.find((el) => {
        if (this.$route.path.indexOf(el.path) > -1) {
          return el
        }
      }) || {}
    this.barActive = +barActive.value || 0

    // this.getDeviceData()
  },
  methods: {
    // 切换tab
    handleClickBar(path, ind) {
      const equipment = this.$store.state.equipment
      if (ind === 4) {
        this.$router.push({
          path,
          query: { equipment: equipment, recordType: '1' }
        })
      } else {
        this.$router.push({ path, query: { equipment } })
      }
      this.barActive = ind
    },
    // 切换设备
    handleChangeNum(val) {
      this.$store.commit('SET_EQUIPMENT', val)

      if (this.barActive == 4) {
        this.$router.push({
          path: this.$route.path,
          query: { equipment: val, recordType: '1' }
        })
      } else {
        this.$router.push({
          path: this.$route.path,
          query: { equipment: val }
        })
      }
    },
    // 页面初始化路由
    getEquipment() {
      this.equipment =
        this.$route.query.equipment || this.$store.state.equipment
      this.$store.commit('SET_EQUIPMENT', this.equipment)
      this.recordType = this.$route.query.recordType
      // if (!this.equipment) {
      //   this.equipment = this.options[0].id
      //   this.$router.push({
      //     path: this.$route.path,
      //     query: {
      //       equipment: this.equipment
      //     }
      //   })
      // }
    },
    // 切换设备类型
    handleChangeRecordType(val) {
      this.$router.push({
        path: '/equipment/strategy',
        query: { equipment: this.equipment, recordType: val }
      })
    },
    // 获取所有设备编号
    getDeviceData() {
      getDeviceData({ id: this.$store.state.uid }).then((res) => {
        this.options = res
      })
    }
  },
  //   销毁前清除设备号
  //   销毁前清除设备号
  beforeDestroy() {
    this.$store.commit('REMOVE_EQUIPMENT')
  }
}
</script>

<style lang="scss" scoped>
.equipment {
  width: 100%;
  height: 100%;
  background: url('../../assets/img/shebei-bg.png') no-repeat center center;
  background-size: cover;
  .equipment-bar {
    width: 100%;
    padding: 9px 47px;
    display: flex;
    align-items: center;
    .bar-left {
      height: 100%;
      ::v-deep.el-form-item {
        margin-bottom: 0;
      }
    }
    .bar-main {
      width: 55%;
      margin-left: 4.6%;
      ul {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        li {
          width: 170px;
          height: 48px;
          background: url('../../assets/img/false-select.png') no-repeat center
            center;
          background-size: contain;
          color: #1ed0f8;
          text-align: center;
          line-height: 48px;
          font-size: 18px;
          cursor: pointer;
        }
        li:hover {
          background: url('../../assets/img/true-select.png') no-repeat center
            center;
        }
        li.active {
          background: url('../../assets/img/true-select.png') no-repeat center
            center;
          color: #fff;
        }
      }
    }
    .bar-right {
      height: 100%;
      margin-left: 100px;
      ::v-deep.el-form-item {
        margin-bottom: 0;
      }
    }
  }
}
</style>
